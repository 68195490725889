import { useState, useEffect } from 'react';

function useIsMobile() {
    // Define the media query
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleMediaChange = () => setIsMobile(mediaQuery.matches);

        // Add listener
        mediaQuery.addListener(handleMediaChange);

        // Clean up by removing the listener
        return () => mediaQuery.removeListener(handleMediaChange);
    }, []);

    return isMobile;
}

export default useIsMobile;
